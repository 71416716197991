<template>
  <b-container>
    <b-form-group>
      <b-form-select
        v-model="params.classificationId"
        :options="[
          { value: 1, text: 'A' },
          { value: 2, text: 'B' },
          { value: 3, text: 'C' }
        ]"
      />
    </b-form-group>

    <b-form-group>
      <b-form-select
        v-model="params.typeId"
        :options="[
          { value: 1, text: 'Cedente' },
          { value: 2, text: 'Sacado' }
        ]"
      />
    </b-form-group>

    <b-button
      variant="primary"
      @click="getData"
    >
      Consultar
    </b-button>

    <b-card
      v-if="Object.keys(paramsDataAggrupedByParam).length"
      class="mt-2"
    >

      <div
        v-for="(param, key) in paramsDataAggrupedByParam"
        :key="{key}"
      >
        <h4> {{ paramsTranslated[key] || key }} </h4>
        <b-table
          class="text-center"
          :items="param"
          :fields="[
            { label: 'Regra do Intervalo', key: 'monitoring_profile_score_interval_types_id' },
            { label: 'Valor da Regra', key: 'rule' },
            { label: 'Penalidade', key: 'penality'},
            { label: '', key: 'action'}
          ]"
        >
          <template #cell(action)="data">
            <b-button
              :disabled="param.length < 2"
              variant="danger"
              @click="removeRule(data)"
            >
              Remover
            </b-button>
          </template>
          <template #cell(rule)="data">
            <b-form-input
              v-model="data.item.rule.value"
              :disabled="data.item.readonly"
            />
          </template>

          <template #cell(penality)="data">
            <b-form-input
              v-model="data.item.penality"
              :disabled="data.item.readonly"
            />
          </template>

          <template #cell(monitoring_profile_score_interval_types_id)="data">
            <b-form-select
              v-model="data.item.monitoring_profile_score_interval_types_id"
              :options="intervalRules"
              :disabled="data.item.readonly"
            />
          </template>
        </b-table>

        <div class="w-full d-flex justify-content-center">
          <b-button
            class="my-1 mx-auto"
            variant="primary"
            @click="addRule(key)"
          >
            Adicionar Regra
          </b-button>
        </div>
      </div>

      <b-button
        variant="primary"
        @click="save"
      >
        Salvar
      </b-button>
    </b-card>
  </b-container>
</template>

<script>
import {
    BTable, BContainer, BFormSelect, BFormGroup, BButton, BCard, BFormInput,
} from 'bootstrap-vue';

import MonitoringProfileService from '@/service/profile-monitoring';

export default {
    components: {
        BTable, BContainer, BFormSelect, BFormGroup, BButton, BCard, BFormInput,
    },

    data() {
        return {
            allData: [],
            sendData: {
                created: [],
                delete: [],
            },
            paramsTranslated: {
                'Overdue Risk': '% Atraso / Risco',
                Liquidity: 'Liquidez',
                Extended: 'Prorrogação',
                Rebuy: 'Recompra',
                'Volume Operated Last 90 Days': 'Volume Operado dos ultimos 90 Dias',
                Protest: 'Protesto',
            },
            intervalRules: [
                { value: 1, text: 'Maior Que' },
                { value: 2, text: 'Menor Que' },
            ],
            params: {
                classificationId: 1,
                typeId: 1,
            },
            paramsIDs: {
                Coobligation: 1,
                'Judicial Recovery': 2,
                Guarantee: 3,
                'Account Blocked': 4,
                Refin: 5,
                Pefin: 6,
                Protest: 7,
                'Returns Check': 8,
                Extended: 9,
                Liquidity: 10,
                'Overdue Risk': 11,
                Rebuy: 12,
                Tranche: 13,
                'Tranche Days': 14,
                'Volume Operated Last 90 Days': 15,
                Injuction: 16,
                'Volume Operated Monthly': 17,
                TAC: 18,
            },
        };
    },

    computed: {
        paramsDataAggrupedByParam() {
            const existsData = this.allData.map(item => ({ ...item, readonly: true }));
            return [...existsData, ...this.sendData.created].reduce((acc, item) => {
                acc[item.param] = acc[item.param] || [];
                acc[item.param].push(item);
                return acc;
            }, {});
        },
    },

    methods: {
        async getData() {
            this.callLoading(true);
            const { data: { data } } = await MonitoringProfileService.getAllParamsByClassificationIdAndTypeId(this.params.classificationId, this.params.typeId);
            this.allData = data;
            this.callLoading(false);
        },

        async save() {
            const isConfirmed = await this.confirmAnAction('Deseja salvar essas regras?');
            if (!isConfirmed) { return; }
            this.callLoading(true);
            await Promise.all([
                MonitoringProfileService.create(this.sendData.created.map(item => ({ ...item, rule: item.rule.value }))),
                MonitoringProfileService.delete(this.sendData.delete),
            ]);
            this.callLoading(false);
        },

        addRule(param) {
            this.sendData.created.push({
                param,
                penality: 1,
                rule: {
                    value: 1,
                },
                monitoring_profile_score_interval_types_id: this.params.typeId,
                monitoring_profile_score_classification_id: this.params.classificationId,
                monitoring_profile_params_id: this.paramsIDs[param],
                new: true,
                rule_id: this.sendData.created[this.sendData.created.length - 1]?.rule_id + 1 || 0,
            });
        },

        removeRule(param) {
            const { item } = param;
            if (item.new) {
                const index = this.sendData.created.findIndex(rule => rule.rule_id === item.rule_id);
                this.sendData.created.splice(index);
                return;
            }
            this.sendData.delete.push(item.id);
            const index = this.allData.findIndex(rule => rule.id === item.id);
            this.allData.splice(index);
        },
    },
};
</script>
