var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',[_c('b-form-group',[_c('b-form-select',{attrs:{"options":[
        { value: 1, text: 'A' },
        { value: 2, text: 'B' },
        { value: 3, text: 'C' }
      ]},model:{value:(_vm.params.classificationId),callback:function ($$v) {_vm.$set(_vm.params, "classificationId", $$v)},expression:"params.classificationId"}})],1),_c('b-form-group',[_c('b-form-select',{attrs:{"options":[
        { value: 1, text: 'Cedente' },
        { value: 2, text: 'Sacado' }
      ]},model:{value:(_vm.params.typeId),callback:function ($$v) {_vm.$set(_vm.params, "typeId", $$v)},expression:"params.typeId"}})],1),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.getData}},[_vm._v(" Consultar ")]),(Object.keys(_vm.paramsDataAggrupedByParam).length)?_c('b-card',{staticClass:"mt-2"},[_vm._l((_vm.paramsDataAggrupedByParam),function(param,key){return _c('div',{key:{key: key}},[_c('h4',[_vm._v(" "+_vm._s(_vm.paramsTranslated[key] || key)+" ")]),_c('b-table',{staticClass:"text-center",attrs:{"items":param,"fields":[
          { label: 'Regra do Intervalo', key: 'monitoring_profile_score_interval_types_id' },
          { label: 'Valor da Regra', key: 'rule' },
          { label: 'Penalidade', key: 'penality'},
          { label: '', key: 'action'}
        ]},scopedSlots:_vm._u([{key:"cell(action)",fn:function(data){return [_c('b-button',{attrs:{"disabled":param.length < 2,"variant":"danger"},on:{"click":function($event){return _vm.removeRule(data)}}},[_vm._v(" Remover ")])]}},{key:"cell(rule)",fn:function(data){return [_c('b-form-input',{attrs:{"disabled":data.item.readonly},model:{value:(data.item.rule.value),callback:function ($$v) {_vm.$set(data.item.rule, "value", $$v)},expression:"data.item.rule.value"}})]}},{key:"cell(penality)",fn:function(data){return [_c('b-form-input',{attrs:{"disabled":data.item.readonly},model:{value:(data.item.penality),callback:function ($$v) {_vm.$set(data.item, "penality", $$v)},expression:"data.item.penality"}})]}},{key:"cell(monitoring_profile_score_interval_types_id)",fn:function(data){return [_c('b-form-select',{attrs:{"options":_vm.intervalRules,"disabled":data.item.readonly},model:{value:(data.item.monitoring_profile_score_interval_types_id),callback:function ($$v) {_vm.$set(data.item, "monitoring_profile_score_interval_types_id", $$v)},expression:"data.item.monitoring_profile_score_interval_types_id"}})]}}],null,true)}),_c('div',{staticClass:"w-full d-flex justify-content-center"},[_c('b-button',{staticClass:"my-1 mx-auto",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.addRule(key)}}},[_vm._v(" Adicionar Regra ")])],1)],1)}),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.save}},[_vm._v(" Salvar ")])],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }