import Thoth from '../base/thoth';

class ProfileMonitoringService extends Thoth {
    getAllParamsByClassificationIdAndTypeId(classificationId, typeId) {
        return this.fetch({
            url: `/score-monitoring-profile/classification/${classificationId}/type/${typeId}/params`,
        });
    }

    create(data) {
        return this.fetch({
            url: '/score-monitoring-profile/params/rules',
            method: 'POST',
            data: { data },
        });
    }

    delete(data) {
        return this.fetch({
            url: '/score-monitoring-profile/params/rules',
            method: 'DELETE',
            data: { data },
        });
    }
}

export default new ProfileMonitoringService();
